import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

import { Flex, Text } from "../../assets/styles/common";
import Partners from "../../components/HomeHeader/Partners";

import austriaA4CIcon from "../../assets/icons/austria-a-4C-0-8-cm-ug.svg";

import GoogleReviewsElement from "../../components/GoogleReviewsElement";
import styled from "styled-components";

const BauenOderKaufen = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Haus bauen oder kaufen? Wir helfen dir bei der Entscheidung." showCalc={false} />
            <Article>
                <Flex
                    flexDirection={["column", "column", null, "row"]}
                    flexWrap="wrap"
                    alignItems={["center", "center", null, null]}
                >
                    <Flex flexDirection="row" flex="1">
                        <p>
                            <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                                Kostenlose und unverbindliche Beratung
                            </a>
                        </p>
                    </Flex>
                    <Flex
                        flexDirection="row"
                        flex="1"
                        alignItems="center"
                        justifyContent={["center", "center", null, "end"]}
                    >
                        <Flex flexDirection="column" mr={[10]}>
                            <StyledAustriaIcon />
                        </Flex>
                        <Flex flexDirection="column">
                            <GoogleReviewsElement />
                        </Flex>
                    </Flex>
                </Flex>
                <Flex flexDirection="column">
                    <Partners />
                    <Text
                        sx={{
                            textAlign: "center",
                            fontSize: ["xs", "sm"],
                            color: "gray.500",
                            px: 4,
                            py: [7, 5],
                        }}
                    >
                        Wir arbeiten mit den führenden österreichischen Banken zusammen und vergleichen täglich tausende
                        Konditionen, um für dich den besten Kredit zu finden.
                    </Text>
                </Flex>
                <h2>Woran denkst du, wenn du dir dein Traumhaus vorstellst?</h2>
                <p>
                    Sind es Vorstellungen wie große, hohe Fenster, Flügeltüren und viel Raum? Oder speziellere Wünsche
                    wie eine Rutsche vom obersten Stockwerk bis in den Keller? Wie sind die Räume aufgeteilt? Hast du
                    oder deine Familie spezielle Anforderungen, die erfüllt werden müssen? All das sind auf den ersten
                    Blick Fragen, die darüber entscheiden können, ob du dein Haus lieber selbst bauen oder kaufen
                    solltest. Aber so entscheidend sind diese Dinge in Wirklichkeit nicht. Denn jedes gekaufte Haus
                    lässt sich nach eigenen Vorstellungen umgestalten, ganz so wie man möchte – es gehört dann
                    schließlich dir und du kannst damit machen, was du willst.
                </p>
                <p>
                    Die Fragen, die wirklich darüber entscheiden sollten, ob du im Endeffekt ein eigenes Haus bauen oder
                    kaufen solltest, ist eher die Frage nach der Zeit, die dir zur Verfügung steht, den Angeboten, die
                    du findest und vor allem der Frage nach deinem Budget. Die Entscheidung, ein Haus zu bauen oder zu
                    kaufen, ist in beiden Fällen eine bedeutende, die man meistens nur einmal im Leben trifft. In diesem
                    Artikel wollen wir dir bei dieser wichtigen Entscheidung helfen.
                </p>
                <hr />

                <h2>Wann kaufen besser sein kann</h2>
                <p>
                    Wenn du oder dein Familien und Bekanntenkreis nur wenig oder gar kein Vorwissen zum Hausbau besitzt,
                    ist es meist besser, sich für den{" "}
                    <Link to="/artikel/hauskauf/" target="_blank" rel="noreferrer noopener">
                        Kauf
                    </Link>{" "}
                    zu entscheiden. Denn wenn du für alles, was gebaut werden muss, professionelle Firmen anstellen
                    musst, wird der Hausbau sehr schnell ziemlich teuer.
                </p>
                <p>
                    Kannst du die Zeit dafür wirklich investieren? Ein Haus zu bauen ist ein großes Projekt, das ein
                    oder auch mehrere Jahre dauern kann. Meistens ist ein Haus in ein bis zwei Jahren gebaut. In dieser
                    Zeit braucht es von deiner Seite viele Entscheidungsprozesse und großes persönliches Engagement. Der
                    bürokratische Aufwand und die Kommunikation mit Baufirmen und einzelnen Handwerker*innen kann
                    schnell zur Herausforderung werden. Wenn Bauverzögerungen und ungeplante Zusatzkosten hinzukommen,
                    kann dich das ganz schön viele Nerven kosten. Willst du diese nicht investieren und deine Zeit für
                    andere Dinge nutzen, ist die Antwort auf die Frage bauen oder kaufen ganz klar: dann ist ein Kauf
                    für dich die bessere Wahl. Du kannst dich später immer noch dazu entschließen, dein Haus zu
                    renovieren oder zu{" "}
                    <Link to="/artikel/sanierung/" target="_blank" rel="noreferrer noopener">
                        sanieren
                    </Link>
                    .
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Sprich mit einem Experten!
                    </a>
                </p>
                <hr />

                <h2>Wann bauen die bessere Wahl ist</h2>
                <p>
                    Umgekehrt gilt: wenn du Vorwissen zum Hausbau besitzt oder jemanden kennst, der handwerklich
                    geschickt ist und mit dem du viel selbst machen kannst, lohnt es sich sehr viel mehr, ein eigenes
                    Haus zu bauen. Damit sparst du dir sehr viele teure Handwerker*innen und kannst zudem wirklich alles
                    so gestalten, wie du es möchtest.
                </p>
                <p>
                    Hast du Zeit, in ein Bauprojekt zu investieren, kommst du am Ende nicht nur günstiger zu deinem
                    Haus, sondern vielleicht auch zufriedener. Schließlich hat sich dann die ganze Arbeit, die du
                    mitgemacht hast, wirklich ausgezahlt und du lebst in deinem selbst vollbrachten Werk. Offensichtlich
                    bietet der Hausbau mehr Gestaltungsfreiheiten. Alles wird genau so gebaut, wie du dir das vorstellst
                    und ist allein dadurch originell. Das kann ein wichtiges Entscheidungskriterium sein, wenn man sich
                    unsicher ist, ob man bauen oder kaufen soll.
                </p>
                <p>
                    Doch am Ende sind{" "}
                    <Link to="/artikel/baufinanzierung/" target="_blank" rel="noreferrer noopener">
                        finanzielle Fragen
                    </Link>{" "}
                    meist ausschlaggebend. Bei dem eigengenutzten Hausbau kannst du meistens viel mehr Fördermittel als
                    beim Hauskauf in Anspruch nehmen, was diese Option attraktiver macht. Alle Regionen Österreichs{" "}
                    <Link to="/artikel/wohnbauforderung/" target="_blank" rel="noreferrer noopener">
                        fördern
                    </Link>{" "}
                    den Hausbau! Informiere dich also gut zu den Fördergeldern, die dein Bundesland anbietet!
                </p>
                <p>
                    Außerdem unterscheidet sich auch die Kreditauszahlung: Während beim Hauskauf die gesamte Kreditsumme
                    ausbezahlt wird, wird bei dem Hausbau je nach Baufortschritt ausgezahlt, wofür man meistens ein
                    Baukonto verwendet. Das kann zu einem günstigeren Kredit führen, der durch die beantragten
                    Fördergelder auch schneller abbezahlt werden kann.
                </p>
                <p>
                    Von der finanziellen Perspektive aus, ist der Hausbau also meistens günstiger. Aber nicht jede*r hat
                    das nötige Know-How oder die Zeit, die dafür benötigt werden kann. In diesen Fällen ist ein Kauf die
                    bessere Entscheidung. Einen ersten Überblick über die voraussichtlichen Kosten eines Kredits bietet
                    dir übrigens unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    .
                </p>
                <hr />

                <h2>Versteckte Kosten beim Kauf- oder Bauprojekt</h2>
                <p>
                    Grundsätzlich ist{" "}
                    <Link to="/artikel/bauen/" target="_blank" rel="noreferrer noopener">
                        Bauen
                    </Link>{" "}
                    oft günstiger als Kaufen. Doch ein direkter Vergleich zwischen bauen oder kaufen ist schwierig, weil
                    Lage, Größe und Ausstattung vergleichbar sein müssen. Beim Hauskauf sind die Kosten im vorhinein
                    meistens gut zu überblicken, wenn du ein Haus baust, ist es schwierig, bereits im vorhinein zu
                    wissen, wieviel du dafür ausgeben wirst. Deshalb kannst du schon im vorhinein damit rechnen, dass
                    ungeplante Kosten anfallen werden und etwas mehr Budget einplanen. Aber egal ob bauen oder kaufen,
                    es ist immer mit versteckten Kosten zu rechnen. Diese reichen von Renovierungskosten für gekaufte
                    Häuser bis zu{" "}
                    <Link to="/artikel/hausbau-kosten/" target="_blank" rel="noreferrer noopener">
                        Baunebenkosten
                    </Link>{" "}
                    und zusätzlichen Baukosten, wenn etwas schiefgehen sollte und nochmal gemacht werden muss – was
                    häufig vorkommen kann.
                </p>
                <h3>Die Kosten im Überblick</h3>
                <p>Hauskauf:</p>
                <ul>
                    <li>Renovierungs- Umbau- und Sanierungsarbeiten</li>
                    <li>Kaufpreis des Objekts (abhängig von Lage, Ausstattung, Zustand und Größe)</li>
                    <li>Kaufnebenkosten</li>
                </ul>
                <p>Hausbau:</p>
                <ul>
                    <li>Baukosten, abhängig von Baumaterialien, (Sonder-)Ausstattung und Größe deiner Immobilie</li>
                    <li>Grundstückspreis (abhängig von Lage, Größe, usw.)</li>
                    <li>Baunebenkosten und Kaufnebenkosten deines Grundstücks</li>
                </ul>
                <p>
                    Zu allen Nebenkosten, die beim bauen oder kaufen von Eigentum vorkommen können, solltest du dich
                    vorher gut informieren. In diesem Artikel könne wir darauf nicht genauer eingehen, aber wir haben
                    dazu{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        einige andere Artikel
                    </Link>{" "}
                    geschrieben. Du kannst dich außerdem professionell beraten lassen und auch im Internet findest du
                    viele nähere Infos zu diesem Thema.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Erstgespräch vereinbaren
                    </a>
                </p>
                <hr />

                <h2>Exkurs: Und wie teuer ist eine Renovierung?</h2>
                <p>
                    Wie teuer eine Renovierung ist, hängt ganz davon ab, welche Bereiche sie umfassen soll.
                    Renovierungsarbeiten in Küche und Bad sind meistens ziemlich teuer, weil man dafür unterschiedliche
                    Profis braucht (Installateure, Elektriker,Tischler, Fliesenleger, usw.). Wenn du oder Bekannte von
                    dir solche Arbeiten drauf haben, ist die Renovierung natürlich günstiger, aber arbeits- und
                    zeitintensiver.
                </p>
                <p>
                    Je nach Größe der Wohnung betragen Renovierungskosten durchschnittlich zwischen 24.000€ und 60.000€.
                    Aber dabei spielen unterschiedliche Faktoren eine Rolle, wie z.B. Qualität und Art der Materialien,
                    Umfang der Arbeiten und ob du vielleicht einen Architekten oder Architektin dafür einstellen musst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Finanzberatung anfordern
                    </a>
                </p>
                <hr />

                <h2>Fazit</h2>
                <p>
                    Die Frage ob bauen oder kaufen besser ist, lässt sich nur individuell beantworten. Ein Hausbau hat
                    viele Vorteile, kann aber auch schnell zu einem Projekt werden, das einen völlig überfordert. Es
                    gibt dabei unendlich viele Dinge zu beachten und meistens warten viele Herausforderungen auf dich,
                    mit denen du vorher gar nicht gerechnet hast. Es gibt sehr viele schöne Häuser, die bereits gebaut
                    sind und nur darauf warten, von dir entdeckt zu werden. Diese kannst du später immer noch
                    renovieren, wenn du möchtest. Aber bei allen Renovierungs- und Bauprojekten bist du immer gut
                    beraten, wenn du jemanden kennst, der sich damit auskennt und dir helfen kann. Ansonsten solltest du
                    lieber nach einem fertig gebauten Haus suchen, in dem du dich von anfang an wohl fühlst.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"bauenOderKaufen"}></BreadcrumbList>
            <ArticleStructuredData
                page={"bauenOderKaufen"}
                heading={"Haus bauen oder kaufen? Wir helfen dir bei der Entscheidung."}
            />
        </Layout>
    );
};

const StyledAustriaIcon = styled(austriaA4CIcon)`
    width: 77px;
`;

export default BauenOderKaufen;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.bauen-oder-kaufen", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
